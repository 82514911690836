<div class="container">
    <mat-card class="text">
        <p class="title" [innerHTML]="'Share Copy Message Title' | translate"></p>

        <p>
            {{ 'Share Copy Message Before' | translate }}
        </p>
        <p>
            {{ 'Share Copy Check out' | translate }}
            <a [href]="jobUrl" target="_blank" rel="noopener noreferrer">
                {{ jobUrl }}
            </a>   
        </p>     
        <p>
            {{ 'Share Copy Message After' | translate  }}
        </p>
    </mat-card>
    <div class="button-zone">
        <button mat-raised-button color="primary" (click)="copy()">
            <mat-icon>file_copy</mat-icon>
            {{'Share Copy' | translate}}
        </button>
        <button mat-raised-button color="primary" (click)="close()">
            <mat-icon>close</mat-icon>
            {{'Share On' | translate}} {{socialMedia}}
        </button>
    </div>
</div>