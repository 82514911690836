<app-job-overview *ngIf="data" [job]="data.job"></app-job-overview>

<form [formGroup]="recommendationForm" class="form-padding">

  <div class="bottom-padding">
    <mat-label><b>{{"who recommand" | translate}}</b></mat-label>
  </div>

  <div class="name-fields">
    <mat-form-field appearance="outline">
      <mat-label>{{"First Name" | translate}}</mat-label>
      <input matInput formControlName='firstname'>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{"Last Name" | translate}}</mat-label>
      <input matInput formControlName='lastname'>
    </mat-form-field>
  </div>

  <div class="full-width">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Email</mat-label>
      <input matInput type="email" formControlName="email" />
    </mat-form-field>
  </div>

  <div class="full-width">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>LinkedIn</mat-label>
      <input matInput formControlName="link_linkedin" />
    </mat-form-field>
  </div>

  <div class="flex-space-between">
    <mat-label style="flex: 0 0 34%;">{{'Have CV' | translate}}</mat-label>

    <mat-radio-group [value]="haveCv" (change)="onRadioChange($event)" class="flex-justify-space-between">
      <mat-radio-button [value]="true" class="flex-1-text-left">
        <h3>{{'Yes' | translate}}</h3>
      </mat-radio-button>

      <mat-radio-button [value]="false" (change)="deleteFile()" class="flex-1-text-left">
        <h3>{{'No' | translate}}</h3>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="haveCv" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)"
    [ngClass]="{'drag-over': isDragOver}" class="drag-drop-area">

    <input type="file" (change)="onFileSelect($event)" style="display: none;" #fileInput accept=".pdf" />

    <p *ngIf="!selectedFile">{{'DragLabel' | translate}}</p>

    <div>
      <div *ngIf="selectedFile" class="margin-top-16">
        <h4 class="margin-0">{{'Selected File' |translate}}:</h4>
        <p class="margin-0">{{ selectedFile.name }} <mat-icon (click)="deleteFile()" class="red-text">close</mat-icon> </p>
        <p class="gray-text">{{'File Size'| translate}}: {{ (selectedFile.size / (1024 * 1024)).toFixed(2)
          }} MB</p>
      </div>

      <div *ngIf="errorMessage" class="red-text">
        {{ errorMessage }}
      </div>

      <button mat-raised-button color="primary" (click)="fileInput.click()">
        <mat-icon>upload</mat-icon>
        {{'Attach CV' | translate}}
      </button>
    </div>
  </div>

  <div class="bottom-padding">
    <mat-label>{{'recommand Offer' | translate}}*</mat-label>
  </div>
  <div class="radio-card-group" role="group" aria-label="Radio Card Group">
    <mat-radio-group class="radio-group" [value]="offerOption" (change)="onRadioOfferChange($event)"
      [disabled]="data && data.job">
      <mat-radio-button class="radio-card" [value]="true" (change)="selectedAgency=null">
        <div class="card-content">
          <h3>{{'Choose offer' | translate}}</h3>
        </div>
      </mat-radio-button>
      <mat-radio-button class="radio-card" [value]="false" (change)="selectedJobs = [];
      ">
        <div class="card-content">
          <h3>{{'user choose'| translate}}</h3>
          <p>{{'sponatnious candidate' | translate}}</p>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="flex-space-between">
    <button mat-raised-button color="primary" (click)="showOffers()"  *ngIf="offerOption" [disabled]="data && data.job">
      <mat-icon>list</mat-icon>
      {{ 'Choose offer' | translate }}
    </button>
    <div *ngIf="selectedJobs?.length ?? 0 > 0" class="right-padding">
      <div *ngFor="let job of selectedJobs; let i = index" class="offer-item">
        <b>{{ job.title }}</b>
        <button mat-icon-button color="warn" (click)="deleteOffer(i)" aria-label="Delete Offer">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    
  </div>

  <div *ngIf="!offerOption">
    <div class="bottom-padding">
      <mat-label>{{'link to agency' | translate}}*</mat-label>
    </div>
    <mat-form-field class="right-padding full-width" appearance="outline">
      <mat-label>{{'Choose agency'| translate}}</mat-label>
      <mat-select [(value)]="selectedAgency">
        <mat-option *ngFor="let agency of agencies" [value]="agency">
          {{ agency.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="bottom-padding">
    <mat-label> {{'why recommand person'| translate}}
    </mat-label>
  </div>
  <div class="radio-card-group" role="group" aria-label="Radio Card Group">
    <mat-radio-group class="radio-group bottom-padding" formControlName="recommandation_level" >
      <mat-radio-button class="radio-card" value="3">
        <div class="card-content">
          <h3>
            <div class="star-container">
              <mat-icon>star</mat-icon>
              <mat-icon>star</mat-icon>
              <mat-icon>star</mat-icon>
            </div>
          </h3>
          <p>
            {{'know the person' | translate}}
          </p>
        </div>
      </mat-radio-button>
      <mat-radio-button class="radio-card" value="2">
        <div class="card-content">
          <h3>
            <div class="star-container">
              <mat-icon>star</mat-icon>
              <mat-icon>star</mat-icon>
            </div>
          </h3>
          <p> {{'dont know the person' | translate}}
          </p>
        </div>
      </mat-radio-button>
      <mat-radio-button class="radio-card" value="1">
        <div class="card-content">
          <h3>
            <div class="star-container">
              <mat-icon>star</mat-icon>
            </div>
          </h3>
          <p>{{'he asks for recommandation' | translate}}</p>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <mat-hint class="small-text">
    {{'comment'| translate}}
  </mat-hint>
  <mat-form-field appearance="outline" class="full-width">
    <textarea matInput formControlName="description" rows="5"
    [placeholder]="'placeholder know the person' | translate"></textarea>
  </mat-form-field>
  <mat-label class="small-text">{{'protection data' | translate}}
  </mat-label><br>
  <mat-checkbox formControlName="consent">
    {{'I accept' | translate}}
    <a href="https://abylsen.com/mentions-legales/" target="_blank" rel="noopener noreferrer">
      {{'legal mentions' | translate}}
    </a>
    {{'and the' | translate}}
    <a href="https://abylsen.com/politique-de-confidentialite/" target="_blank" rel="noopener noreferrer">
      {{'policy'| translate}}
    </a>
    Abylsen..*
  </mat-checkbox>

  <div class="notification-banner">
    <div class="icon">
      <mat-icon class="zoom-2">beenhere</mat-icon>
    </div>
    <div class="message">
      <strong>{{'no risk title'| translate }}</strong>
      <p>
        {{'no risk message'| translate }}
      </p>
    </div>
  </div>
  
  <div class="text-center">
    <button mat-raised-button color="primary" type="submit" style="width: 100%;"
      [disabled]="!recommendationForm.valid || (!selectedFile && haveCv) || (offerOption && selectedJobs.length===0) || (!offerOption && !selectedAgency) || isLoading"
      (click)="onSubmit()">
      <!-- Conditionally display text or spinner based on loading state -->
      <ng-container *ngIf="!isLoading; else loadingSpinner">
        {{ 'recommand' | translate }}
      </ng-container>
      <ng-template #loadingSpinner>
        <mat-spinner diameter="24"></mat-spinner>
      </ng-template>
    </button>
  </div>
  
</form>