import { Component, Inject } from "@angular/core";
import { TranslationsService } from "../../../services/translations.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-share-offer-popup',
  templateUrl: './share-offer-popup.component.html',
  styleUrl: './share-offer-popup.component.scss',
  standalone: false
})


export class ShareOfferPopupComponent {


  public jobUrl?: string = 'wrong Linked';
  public socialMedia?: string = 'wrong Linked';

  
  constructor(
    private dialogRef: MatDialogRef<ShareOfferPopupComponent>, // Référence au dialogue
    @Inject(MAT_DIALOG_DATA) public data: { socialMedia : string,  jobUrl : string }, // Données injectées
    private translate: TranslationsService
  ) {
    this.socialMedia = this.data?.socialMedia;
    this.jobUrl = this.data?.jobUrl; // Assigner la valeur injectée à une variable locale
  }

  copy() {
    const url = this.jobUrl;
    const visibleText = "Cliquez ici pour voir l'offre";
    const htmlContent = `<a href="${this.jobUrl}">${visibleText}</a>`;
    const clipboardItem = new ClipboardItem({
      'text/html': new Blob([htmlContent], { type: 'text/html' }),
      'text/plain': new Blob([visibleText + " : " + url], { type: 'text/plain' }) // Texte brut comme fallback
    });

    const text = this.translate.translate('Share Copy Message Before') + '\n' + url + '\n' + this.translate.translate('Share Copy Message After');

    // Utiliser l'API Clipboard pour copier le texte
    navigator.clipboard.writeText(text).then(() => {
      console.log('Texte copié avec succès !', text);
    }).catch(err => {
      console.error('Erreur lors de la copie :', err);
    });
  }

  close() { 
    this.copy();
    this.dialogRef.close();
  }

}