import { Cooptation } from './../../data/cooptation';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobSearchService } from '../../services/job-search.service';
import { DialogService } from '../../services/dialog.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-cooptation-card',
  templateUrl: './cooptation-card.component.html',
  styleUrl: './cooptation-card.component.scss',
  standalone: false
})
export class CooptationCardComponent implements OnInit {
  @Input() currentState: number = 0; // State from 0 to 5
  @Input() rating: number = 0; // Rating from 1 to 3
  @Input() cooptation!: Cooptation;
  @Output() relanceEvent = new EventEmitter<Cooptation>();

  readonly byLink = 'Via lien'
  readonly byForm = 'Via formulaire'
  public steps!: { label: string; icon: string; }[];
  constructor(

    public service: JobSearchService,
    public dialogService: DialogService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {

    this.matIconRegistry.addSvgIcon(
      'custom_history',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/logo-status/custom_history.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'custom_person_check',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/logo-status/custom_person_check.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'custom_person_off',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/logo-status/custom_person_off.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'custom_person',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/logo-status/custom_person.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'custom_dialogue',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/logo-status/custom_dialogue.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'custom_document',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/logo-status/custom_document.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'custom_checked',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/logo-status/custom_checked.svg')
    );
  }

  ngOnInit(): void {
    this.steps = [
      { label: 'Pending validation', icon: 'custom_history' },
      { label: 'Interview in progress', icon: 'custom_checked' },
      { label: 'Offer made', icon: 'custom_dialogue' },
      { label: 'Application result', icon: 'custom_document' },
      { label: 'Candidate in reserve', icon: 'custom_person_check'},
    ];
    if (this.cooptation.state <0 ) {
            this.steps[4] ={ label: 'Application not retained', icon: 'custom_person_off' };
    }
  }


  public relance(coopt: Cooptation) {
    this.relanceEvent.emit(coopt);
  }



  getRecLevel(cooptation: Cooptation) {
    if (cooptation) {
      return cooptation.recommandation_level
    } else {
      return 1
    }
  }


  getState(cooptation: Cooptation) {
    if (cooptation) {
      return cooptation.state
    } else {
      return 0
    }
  }

  isDateOlderThan24Hours(updatedAt: Date | undefined): boolean {
    if (updatedAt) {
      const updatedDate = new Date(updatedAt).getTime();
      const currentDate = new Date().getTime();
      const diffInHours = (currentDate - updatedDate) / (1000 * 60 * 60);
      return diffInHours > 24;
    }
    else
      return false
  }

  isStepIconBordered(stepIndex: number): boolean {
    return stepIndex >= 1 && stepIndex <= 3;
  }

  getStepIconColor(stepIndex: number): string {
    return stepIndex <= this.getState(this.cooptation) ? '#a5e71c' : '#ddd';
  }

  getStepIconBorderColor(stepIndex: number): string {
    const currentState = this.getState(this.cooptation);
    return stepIndex === currentState && this.isStepIconBordered(stepIndex) ? '#a5e71c' : '#ddd';
  }

  hasError(): boolean {
    return this.getState(this.cooptation) < 0;
  }


}
